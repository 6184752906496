import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { setToast } from '@/core/services/Toast/store/ToastSlice'
import { useAppDispatch } from '@/core/store/hooks'

import { Icon } from '@/components/Icon'
import { Item, Items } from '@/components/Items'

import { ITransaction } from '../../models/TransactionsModel'
import './PaymentAddress.scss'

const cnPaymentAddress = cn('PaymentAddress')

interface IPaymentAddress {
  transaction: ITransaction
  className?: string
}

export const PaymentAddress: FC<IPaymentAddress> = ({
  transaction,
  className,
}) => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()

  const onCopy = () => {
    dispatch(
      setToast({
        type: 'success',
        message: t('Copied'),
      }),
    )
  }

  return (
    <div className={cnPaymentAddress(null, [className])}>
      <Items>
        <Item label={t('You get')} value={transaction.amount} />
        <Item label={t('Amount to send')}>
          <div className={cnPaymentAddress('copy')}>
            {transaction.give_sum}

            <CopyToClipboard text={transaction.give_sum} onCopy={onCopy}>
              <Icon name='copy-circle' />
            </CopyToClipboard>
          </div>
        </Item>

        {transaction.pay_wallet && transaction.pay_wallet.wallet && (
          <Item full label={t('Address wallet SITE')}>
            <div className={cnPaymentAddress('copy')}>
              {transaction.pay_wallet?.wallet}

              <CopyToClipboard
                text={transaction.pay_wallet.wallet}
                onCopy={onCopy}
              >
                <Icon name='copy-circle' />
              </CopyToClipboard>
            </div>
          </Item>
        )}
      </Items>
    </div>
  )
}
