import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import { Icon } from '@/components/Icon'
import { Logo } from '@/components/Logo'

import { userSelector } from '@/modules/Auth/store/AuthSelector'
import { countNotificationsSelector } from '@/modules/Notifications/store/notifications.selector'

import './Header.scss'

const cnHeader = cn('Header')

export const Header: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const navigate = useNavigate()

  const count = useAppSelector(countNotificationsSelector)
  const user = useAppSelector(userSelector)

  const logout = () => {
    navigate('/')
    localStorage.removeItem('token')
  }

  return (
    <header className={cnHeader()}>
      <div className={cnHeader('line')}>
        <Logo
          className={cnHeader('logo')}
          src='/img/logo-white.svg'
          to={'/profile'}
        />
        <div className={cnHeader('right')}>
          {/* <div className={cnHeader('theme')}>
            {t('ON')}
            <Icon className={cnHeader('icon')} name='sun' />
          </div>
          <div className={cnHeader('lang')}>
            {t('En')}
            <Icon className={cnHeader('icon')} name='arrow-down-header' />
          </div> */}
          <button
            type='button'
            className={cnHeader('notification')}
            onClick={() => navigate('/profile/notifications')}
          >
            {count > 0 ? (
              <Icon name='notification-chip' />
            ) : (
              <Icon name='notification-chip-empty' />
            )}
          </button>

          {user && (
            <button
              type='button'
              className={cnHeader('logout')}
              onClick={logout}
            >
              <Icon name='logout' />
              {t('Logout')}
            </button>
          )}
        </div>
      </div>
    </header>
  )
})
