/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import SumsubWebSdk from '@sumsub/websdk-react'
import { AxiosResponse } from 'axios'
import { FC, FocusEvent, useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { IFieldValueVerify, IUserResponseVerify } from '@/core/interfaces'
import {
  fieldsSelector,
  verifyInfoSelector,
} from '@/core/services/App/store/AppSelector'
import {
  deleteFileVerifyManualThunk,
  getTokenVerifyThunk,
  getVerifyManualThunk,
  sendValueVerifyManualThunk,
  sendVerifyManualThunk,
  uploadVerifyManualThunk,
} from '@/core/services/App/store/AppSlice'
import { setToast } from '@/core/services/Toast/store/ToastSlice'
import { useAppDispatch, useAppSelector } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'

import yup from '@/utils/yup-extended'

import { AppContent, AppProfile } from '@/components/AppProfile'
import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Field } from '@/components/Field'
import { Icon } from '@/components/Icon'
import { Title } from '@/components/Title'
import { Upload } from '@/components/Upload'

import { userSelector } from '../Auth/store/AuthSelector'
import { getCurrentUserThunk } from '../Auth/store/AuthSlice'
import './styles/UserVerification.scss'

const cnUserVerification = cn('UserVerification')

export const UserVerification: FC = Memo(() => {
  const { t, i18n } = useTranslation('translation')
  const [setBackendError] = useBackendError()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(userSelector)
  const fields = useAppSelector(fieldsSelector)
  const verifyInfo = useAppSelector(verifyInfoSelector)

  const [data, setData] = useState<IUserResponseVerify | null>(null)
  const [accessToken, setAccessToken] = useState<string>('')
  
  // Updated Sumsub SDK options for v2
  const options = { 
    addViewportTag: false,
    adaptIframeHeight: true,
    uiConf: {
      customCssStr: '' // Can add custom CSS if needed
    }
  }

  const getTokenVerify = async () => {
    if (!user) return

    try {
      const res = await dispatch(
        getTokenVerifyThunk({
          email: user.email,
          phone: user.phone,
        }),
      ).unwrap()

      setData(res)
      setAccessToken(res.access_token)

      return res.access_token
    } catch (errors) {
      setBackendError(errors)
      const err = errors as AxiosResponse
      if (err && err.status >= 500) {
        navigate('/profile')
      }

      if (
        err &&
        err.data &&
        err.data.error &&
        err.data.error === 'is_verified'
      ) {
        navigate('/profile')
      }
    }
  }

  useEffect(() => {
    if (user && !!user.is_verify) {
      navigate('/profile')
    }

    if (user) {
      getTokenVerify()
    }
  }, [user])

  // Updated message handler for Sumsub v2
  const messageHandler = useCallback((eventType: string, payload: any) => {
    console.log('Sumsub event:', eventType, payload)

    // Handle onApplicantStatusChanged - это основное событие в новой версии
    if (eventType === 'idCheck.onApplicantStatusChanged') {
      console.log('Status changed:', payload.reviewStatus, payload.reviewResult)
      
      if (payload?.reviewStatus === 'completed' && payload?.reviewResult?.reviewAnswer === 'GREEN') {
        console.log('Verification completed successfully, updating user and redirecting...')
        // Обязательно ждем завершения запроса перед переходом
        getUser().then((success) => {
          if (success) {
            dispatch(
              setToast({
                type: 'success',
                message: t('Verification completed successfully'),
              })
            )
            // Добавим небольшую задержку перед навигацией
            setTimeout(() => {
              navigate('/profile')
            }, 1000)
          }
        })
      }
    }
    
    // Обработка других событий для совместимости
    if (eventType === 'idCheck.applicantReviewed') {
      if (payload?.reviewResult?.reviewAnswer === 'GREEN') {
        getUser().then(() => {
          navigate('/profile')
        })
      }
    }
    
    if (eventType === 'idCheck.applicantCreated') {
      console.log('Applicant created successfully')
    }
    
    if (eventType === 'idCheck.onError') {
      console.error('Sumsub error:', payload)
      dispatch(
        setToast({
          type: 'error',
          message: t('Verification error occurred'),
        })
      )
    }
    
    // Поддержка старого события для совместимости
    if (eventType === 'idCheck.applicantStatus' && payload?.reviewStatus === 'completed') {
      getUser().then(() => {
        navigate('/profile')
      })
    }
  }, [navigate, dispatch, t])

  const getUser = async () => {
    try {
      await dispatch(getCurrentUserThunk()).unwrap()
      return true
    } catch (errors) {
      console.log('getUser error:', errors)
      setBackendError(errors)
      return false
    }
  }

  // Token expiration handler for Sumsub v2
  const getResendTokenVerify = useCallback(async () => {
    console.log('Token expired, requesting new token')
    try {
      const newToken = await getTokenVerify()
      return newToken || ''
    } catch (error) {
      console.error('Failed to refresh token:', error)
      return ''
    }
  }, [user])

  useEffect(() => {
    if (data && data.provider === 'manual') {
      getVerifyManual()
    }
  }, [data])

  const getVerifyManual = async () => {
    try {
      await dispatch(getVerifyManualThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const handleChange = async (e: File, name: string) => {
    const formData = new FormData()
    formData.append('filename', name)
    formData.append('file', e)

    try {
      await dispatch(uploadVerifyManualThunk(formData)).unwrap()

      await getVerifyManual()
    } catch (errors) {
      setBackendError(errors)

      const err = errors as AxiosResponse

      if (err && err.data && err.data.errors && err.data.errors.file) {
        dispatch(
          setToast({
            type: 'error',
            message: t(err.data.errors.file[0]),
          }),
        )
      }
    }
  }

  const sendValueVerifyManual = async (e?: FocusEvent<HTMLInputElement>) => {
    if (!e || e.target.value.length === 0) return

    const data: IFieldValueVerify = {
      filename: e.target.name,
      value: e.target.value,
    }

    try {
      await dispatch(sendValueVerifyManualThunk(data)).unwrap()

      await getVerifyManual()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  useEffect(() => {
    if (fields.length) {
      const obj = fields
        .filter(el => el.type === 'input')
        .map(el => {
          return {
            name: el.name,
            value: el.file && el.file.filename,
          }
        })
        .reduce((a, v) => ({ ...a, [v.name]: v.value || '' }), {})

      reset(obj)
    }
  }, [fields])

  const schema = yup.object().shape({
    surname: yup.string().trim().required(t('This field is required')),
    name: yup.string().trim().required(t('This field is required')),
    address: yup.string().trim().required(t('This field is required')),
    city: yup.string().trim().required(t('This field is required')),
    country: yup.string().trim().required(t('This field is required')),
    zip_code: yup.string().trim().required(t('This field is required')),
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  const onSubmit = async () => {
    try {
      await sendVerifyManual()

      await getUser()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const sendVerifyManual = async () => {
    try {
      await dispatch(sendVerifyManualThunk()).unwrap()
    } catch (errors) {
      setBackendError(errors)
    }
  }

  const deleteFile = async (name: string) => {
    try {
      await dispatch(deleteFileVerifyManualThunk(name)).unwrap()

      await getVerifyManual()
    } catch (errors) {
      setBackendError(errors)
      const err = errors as AxiosResponse

      if (err && err.data && err.data.errors && err.data.errors.files) {
        dispatch(
          setToast({
            type: 'error',
            message: t('Copied'),
          }),
        )
      }
    }
  }

  // Error handling function for Sumsub v2
  const errorHandler = useCallback((error: any) => {
    console.error('Sumsub SDK error:', error)
    dispatch(
      setToast({
        type: 'error',
        message: t('Verification system error, please try again later'),
      })
    )
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('Identity document')}</title>
        <meta name='description' content={t('transactions-description')} />
      </Helmet>
      <AppProfile>
        <AppContent>
          <Title>{t('Identity document')}</Title>

          {data && (
            <>
              {data.provider === 'sumsub' && accessToken && (
                <div className={cnUserVerification('sumsub-container')}>
                  <SumsubWebSdk
                    accessToken={accessToken}
                    expirationHandler={getResendTokenVerify}
                    config={{
                      lang: i18n.language,
                    }}
                    options={options}
                    onMessage={messageHandler}
                    onError={errorHandler}
                  />
                </div>
              )}
            </>
          )}

          {verifyInfo && verifyInfo.commit && (
            <Card className={cnUserVerification('card', { top: true })}>
              <div className={cnUserVerification('text')}>
                {verifyInfo.commit}
              </div>

              {verifyInfo.status && (
                <Icon
                  name={verifyInfo.status.name}
                  className={cnUserVerification('icon')}
                />
              )}
            </Card>
          )}

          {fields.length > 0 && (
            <form
              className={cnUserVerification('form')}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={cnUserVerification('grid')}>
                {fields.map(el => (
                  <Card className={cnUserVerification('card')} key={el.name}>
                    <label
                      htmlFor={el.name}
                      className={cnUserVerification('label')}
                    >
                      {t('verification-' + el.name)}
                    </label>

                    {el.file && el.file.status && (
                      <Icon
                        name={el.file.status.name}
                        className={cnUserVerification('icon')}
                      />
                    )}

                    {el.type === 'input' && (
                      <div className={cnUserVerification('field')}>
                        <Field
                          id={el.name}
                          placeholder={
                            t('Enter') +
                            ' ' +
                            String(t('verification-' + el.name)).toLowerCase()
                          }
                          sizeInput='lg'
                          mb='none'
                          errors={
                            errors &&
                            errors[el.name] &&
                            String(errors[el.name]?.message)
                          }
                          {...register(el.name)}
                          handleOnBlur={sendValueVerifyManual}
                          disabled={
                            el &&
                            el.file &&
                            el.file.status &&
                            (el.file.status.name === 'approved' ||
                              el.file.status.name === 'under_review')
                          }
                          readOnly={
                            el &&
                            el.file &&
                            el.file.status &&
                            (el.file.status.name === 'approved' ||
                              el.file.status.name === 'under_review')
                          }
                        />
                      </div>
                    )}

                    {el.type === 'file' && (
                      <div
                        className={cnUserVerification('field', {
                          center: el.file && !!el.file.filename,
                        })}
                      >
                        {el.file && el.file.filename ? (
                          <>
                            <div className={cnUserVerification('filename')}>
                              {el.file.filename}
                            </div>

                            {el &&
                              el.file &&
                              el.file.status &&
                              el.file.status.name !== 'approved' &&
                              el.file.status.name !== 'under_review' && (
                                <button
                                  type='button'
                                  className={cnUserVerification('delete')}
                                  onClick={() => deleteFile(el.name)}
                                >
                                  <Icon name='delete' />
                                </button>
                              )}
                          </>
                        ) : (
                          <Upload
                            name={el.name}
                            handleChange={e => handleChange(e, el.name)}
                            text={t('download-document')}
                            size='sm'
                            accept='application/pdf'
                          />
                        )}
                      </div>
                    )}

                    {el.file && el.file.commit && (
                      <div className={cnUserVerification('commit')}>
                        {el.file.commit}
                      </div>
                    )}
                  </Card>
                ))}
              </div>
              <div className={cnUserVerification('action')}>
                <Button type='submit' isLoading={isSubmitting}>
                  {t('Send a request for verification')}
                </Button>
              </div>
            </form>
          )}
        </AppContent>
      </AppProfile>
    </>
  )
})